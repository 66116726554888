// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';
import Iconify from '../../../components/iconify/Iconify';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  dashboard: icon('Icons=dashboard'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  eventTags: icon('ic_dashboard'),
  ticket_orders: icon('ticket_orders'),
  sponsored_events: icon('sponsored_events'),
  tickets: icon('Icons=Tickets'),
  customers: icon('customers'),
  campaign: icon('campaign'),
  maintenance: icon('maintenance'),
  events:icon("sponsored_events"),
  discounts:icon("campaign")
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.dashboard.root, icon: ICONS.dashboard },
      { title: 'Vendor', path: PATH_DASHBOARD.vendors.root, icon: ICONS.user },
      { title: 'Business Tags', path: PATH_DASHBOARD.businessTags.root, icon: ICONS.ecommerce },
      // { title: 'Packages', path: PATH_DASHBOARD.packages.root, icon: ICONS.analytics },
      { title: 'Event Tags', path: PATH_DASHBOARD.eventTags.root, icon: ICONS.eventTags },

      {
        title:'Events',
        path: PATH_DASHBOARD.events.root,
        icon: ICONS.events
      },

      {
        title:'Discount',
        path: PATH_DASHBOARD.discount.root,
        icon: ICONS.discounts
      },
      
      {
        title: 'Ticket Orders',
        path: PATH_DASHBOARD.ticket_orders.root,
        icon: ICONS.ticket_orders,
      },
      {
        title: 'Sponsored Events',
        path: PATH_DASHBOARD.sponsoredEvents.root,
        icon: ICONS.sponsored_events,
      },
      {
        title: 'Tickets',
        path: PATH_DASHBOARD.cancelTickets.root,
        // icon: <Iconify icon="eva:close-circle-outline" />,
        icon: ICONS.tickets,
      },
      {
        title: 'Event Group',
        path: PATH_DASHBOARD.groupEvents.root,
        // icon: <Iconify icon="eva:close-circle-outline" />,
        icon: ICONS.tickets,
      },
      {
        title: 'Invoices',
        icon: ICONS.tickets,
        path: PATH_DASHBOARD.invoices.root, // This should lead to the base path for invoices
        children: [
          {
            title: 'Vendor Invoices',
            path: PATH_DASHBOARD.invoices.root, // Adjust this to match the correct path for vendor invoices
          },
          {
            title: 'Customer Invoices',
            path: PATH_DASHBOARD.invoices.customer, // This should be the path leading to the customer invoices
          },
        ],
      },

      {
        title: 'Customer',
        path: PATH_DASHBOARD.customer.root,
        icon: ICONS.customers,
      },

      {
        title: 'Campaign',
        icon: ICONS.campaign,
        // path: `${PATH_DASHBOARD}/campaign`,
        path: PATH_DASHBOARD.campaignPackage.root,
        children: [
          {
            title: 'Credits Package Price',
            path: PATH_DASHBOARD.campaignPackage.root,
          },
          {
            title: 'Add Credits',
            path: PATH_DASHBOARD.campaignCredits.root,
          },
          {
            title: 'Add Templates',
            path: PATH_DASHBOARD.campaignTemplate.root,
          },
        ],
      },
      {
        title: 'Maintenance',
        path: PATH_DASHBOARD.maintenance.root,
        icon: ICONS.maintenance,
      },
    ],
  },
];

export default navConfig;
