import { createContext, useContext } from 'react';
import { Outlet } from 'react-router-dom';

export const EventsTemplateContext = createContext();
export const EventDetailsContext = () => {
    const context = useContext();
    if (!context) throw new Error('useSettingsContext must be use inside SettingsProvider');
    return context;
};

export function DiscountTemplateProvider() {
    const memoizedValue = ""

    return (
        <EventsTemplateContext.Provider value={memoizedValue}>
            <Outlet />
        </EventsTemplateContext.Provider>
    );
}
